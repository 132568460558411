import { useRecoilState } from 'recoil'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MarqueeCarousel from '../../components/auto-carusel'
import CustomCarousel from '../../components/custom-carusel'
import FurnitureCollection from '../../components/furniture-collection'
import FurnitureShowCase from '../../components/furniture-showcase'
import FurnitureWithMenu from '../../components/furniture-with-menu'
import Header from '../../components/header'
import ProjectSection from '../../components/project-section'
import { mockImagesSlider } from '../../data/catalogItem'
import {
  selectedCategoriesState,
  selectedDiscoutState,
} from '../../store/atoms'

import './style.scss'

const Main = () => {
  const navigate = useNavigate()
  const [discount, setDiscount] = useRecoilState(selectedDiscoutState)
  const [cats, setSelectedCategories] = useRecoilState(selectedCategoriesState)

  const noContentIds = [100, 14, 16]

  const redirectToCatalog = (id) => {
    if (noContentIds.includes(id)) {
      //TODO revert when discount present
      // setDiscount(true)
    } else {
      setSelectedCategories([id])
    }

    navigate('/catalog')
  }

  return (
    <div>
      <Header isAbsolute />
      <div className="main container">
        <CustomCarousel
          hideTextUntilActive={false}
          slides={mockImagesSlider}
          main
        />
        <FurnitureShowCase redirectToCatalog={redirectToCatalog} />
        <FurnitureWithMenu redirectToCatalog={redirectToCatalog} />
        <FurnitureCollection redirectToCatalog={redirectToCatalog} />
        <MarqueeCarousel />
        <ProjectSection />
      </div>
    </div>
  )
}

export default Main
